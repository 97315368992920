import type { FormKitNode } from '@formkit/core';

export function useValidationRow() {
  const group = ref<FormKitNode>();
  const messages = reactive<Record<string, string[]>>({});
  const displayMessages = computed(() => {
    return Object.values(messages).flat();
  });
  const showMessages = computed(() => displayMessages.value.length > 0);

  watch(group, (group) => {
    (group?.children ?? []).forEach((child) => {
      watch(
        () => (child as FormKitNode).context?.messages,
        (cur) => {
          if (!messages[child.name]) messages[child.name] = [];
          if (cur && Object.keys(cur).length) {
            messages[child.name] = Object.values(cur).map(
              (v) => v.value as string,
            );
          } else {
            messages[child.name] = [];
          }
        },
      );
    });
  });

  return {
    group,
    messages,
    displayMessages,
    showMessages,
  };
}
