<template>
  <div
    :class="[
      'group validation-row',
      {
        'rounded-fields': isMinilu,
        'has-messages': showMessages,
      },
    ]"
    class="mb-sm"
  >
    <label v-if="label">
      <FormLabel :label="label" :required="required" />
    </label>
    <FormKit
      type="group"
      :parent="parentNode"
      :name="name"
      @node="(node) => (group = node)"
    >
      <template #default>
        <div class="flex flex-row gap-sm">
          <slot />
        </div>
        <div
          v-if="showMessages"
          class="formkit-messages-custom"
          :class="{
            'bg-minilu-bg rounded-br-minilu-md rounded-bl-minilu-md text-minilu-red':
              isMinilu,
            'bg-vdv-bg-base text-sign-red': !isMinilu,
          }"
        >
          <template v-if="validationMessage">
            {{ t(validationMessage) }}
          </template>
          <template
            v-else-if="
              combinedValidationMessage &&
              (Object.values(messages).every((cur) => cur.length) ||
                (combinedMessagesOnly && Object.values(messages).length > 0))
            "
          >
            {{ t(combinedValidationMessage) }}
          </template>
          <template v-else>
            <div v-for="(msg, i) in displayMessages" :key="i">
              {{ t(msg) }}
            </div>
          </template>
        </div>
      </template>
    </FormKit>
  </div>
</template>
<script setup lang="ts">
import { FormLabel } from '@/complib';
import { useValidationRow } from './useValidationRow';
import { FormKit } from '@formkit/vue';
import { SiteIdent } from '~/utils/types';
import type { FormKitNode } from '@formkit/core';

defineProps({
  name: {
    type: String,
    default: '',
  },
  validationMessage: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: true,
  },
  combinedValidationMessage: {
    type: String,
    default: '',
  },
  combinedMessagesOnly: {
    type: Boolean,
    default: false,
  },
  parentNode: {
    type: Object as PropType<FormKitNode>,
    default: null,
  },
});

const { messages, showMessages, displayMessages, group } = useValidationRow();
const { t } = useTrans();

const isMinilu = useSiteIdent() !== SiteIdent.default;
</script>
<style scoped lang="postcss">
.group :deep(.formkit-outer) {
  @apply !mb-0;
  .formkit-messages {
    @apply !hidden;
  }
}

.group.has-messages :deep(input) {
  @apply rounded-br-none rounded-bl-none;
}

.group :deep(.formkit-messages-custom) {
  @apply px-sm py-[2px];
}
</style>
